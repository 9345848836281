import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_CHECKOUT_SESSION } from '@ec/apollo/src/mutations/subscriptions'
import { GET_SUBSCRIPTION_PRODUCTS } from '@ec/apollo/src/queries/subscriptions'
import { SubscriptionProduct } from '@ec/types'
import { Button, CheckIcon, Toast } from '@ec/ui'
import { useNotifier } from 'react-headless-notifier'
import PricingTableSkeleton from './Skeletons/PricingTableSkeleton'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { getDisplayPrice } from '@ec/ui/src/helpers/currency'

const PricingTable = () => {

  const { notify } = useNotifier()

  const [isCheckoutSessionLoading, setIsCheckoutSessionLoading] = useState<boolean>(false)
  const [selectedProduct, setSelectedProduct] = useState<SubscriptionProduct | null>(null)
  const [sortedProducts, setSortedProducts] = useState<SubscriptionProduct[]>([])

  const { data: products, loading: productsLoading } = useQuery<{ subscriptionProducts: [SubscriptionProduct] }>(GET_SUBSCRIPTION_PRODUCTS)

  const [createCheckoutSessionMutation, { data: checkoutResponse, loading: checkoutLoading, error }] = useMutation<{ createCheckoutSession: string }>(CREATE_CHECKOUT_SESSION)

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  useEffect(() => {
    if (checkoutResponse?.createCheckoutSession) {
      window.location.href = checkoutResponse.createCheckoutSession
    }
  }, [checkoutResponse])

  useEffect(() => {
    if (checkoutLoading) {
      setIsCheckoutSessionLoading(true)
    }
  }, [window?.location])

  useEffect(() => {
    if (error) {
      notify(<Toast type="error" title="Loading Error" message="There was an issue requesting the checkout portal, please try again" />)
      setIsCheckoutSessionLoading(false)
      setSelectedProduct(null)
    }
  }, [error])

  useEffect(() => {
    if (products?.subscriptionProducts) {
      let productsCopy = products.subscriptionProducts.slice(0)

      setSortedProducts(productsCopy.sort(compareProducts))
    }
  }, [products])

  const compareProducts = (a: SubscriptionProduct, b: SubscriptionProduct) => {
    if (a.default_price.unit_amount < b.default_price.unit_amount) {
      return 1
    } else if (a.default_price.unit_amount > b.default_price.unit_amount) {
      return -1
    } else {
      return 0
    }
  }

  return (
    <div className="mx-auto bg-white">
      {/* Tiers */}
      <div className="mt-14 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
        {
          !productsLoading ?
            <>
              {
                sortedProducts && sortedProducts.map((product) => (
                  <div
                    key={product.name}
                    className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm w-full min-h-[450px] max-w-[500px] mx-auto lg:max-w-[375px]"
                  >
                    <div className="flex-1">
                      <h3 className="text-xl font-semibold text-gray-900">{product.name}</h3>

                      {
                        product.metadata.recommended &&
                        <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-indigo-500 py-1.5 px-4 text-sm font-semibold text-white">
                          Recommended
                        </p>
                      }
                      <p className="mt-6 mb-1 font-medium text-gray-500">Starting From</p>
                      <p className="flex items-baseline text-gray-900">
                        <span className="text-4xl font-bold tracking-tight">{getDisplayPrice(product.default_price.unit_amount * product.volunteerMinimum, product.default_price.currency)}</span>
                        <span className="ml-1 text-xl font-semibold">Monthly</span>
                      </p>
                      <p className="mt-2 text-xs text-gray-700">Minimum {product.volunteerMinimum} volunteers at {getDisplayPrice(product.default_price.unit_amount, product.default_price.currency)}</p>

                      {/* Feature list */}
                      <ul className="my-10 space-y-6">
                        {
                          product.metadata.features && product.metadata.features.split(/\s*,\s*/).map((feature) => (
                            <li key={feature} className="flex">
                              <CheckIcon className="h-6 w-6 flex-shrink-0 text-indigo-500" aria-hidden="true" />
                              <span className="ml-3 text-gray-500">{feature}</span>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                    <div className="mt-4">
                      <Button block isLoading={isCheckoutSessionLoading && selectedProduct?.id === product.id} onClick={() => {
                        setIsCheckoutSessionLoading(true)
                        setSelectedProduct(product)
                        createCheckoutSessionMutation({ variables: { product_id: product.id } })
                      }}>
                        {
                          user?.organisation && user.organisation.subscription ?
                            <span>Subscribe from {getDisplayPrice(product.default_price.unit_amount * product.volunteerMinimum, product.default_price.currency)} monthly</span>
                            :
                            <span>Start Free Trial</span>
                        }
                      </Button>
                    </div>
                  </div>
                ))}
            </>
            :
            <>
              <PricingTableSkeleton />
              <PricingTableSkeleton />
              <PricingTableSkeleton />
            </>

        }
      </div>
    </div>
  )
}

export default PricingTable